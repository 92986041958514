import { Component, Input, OnInit } from '@angular/core';
import { ListService } from '@app/common/services/list.service';
import { StateService } from '@uirouter/angular';
import { OccupationGroupsType, ParcelService } from '@app/ps/services/parcel.service';
import { SettingsService } from '@app/ps/services/settings.service';
import { AuthService } from '@app/common/services/auth.service';
import { ListModel } from '@app/common/models/list.model';
import { NoteModel } from '@app/common/models/note.model';
import { ColumnDefinition } from '@app/common/models/column-definition.model';
import { TableUtils } from '@app/common/utils/table.utils';
import { ParcelModel } from '@app/common/models/parcel.model';
import { CaseModel } from '@app/ps/models/case.model';
import { TabModel } from '@app/common/models/tab.model';
import { OccupationModel } from '@app/ps/models/occupation.model';
import { EasementModel } from '@app/common/models/easement.model';
import { DocumentModel } from '@app/common/models/document.model';
import { HelpService } from '@app/common/services/help.service';
import { Restangular } from 'ngx-restangular';
import {BrandService} from "@app/common/services/brand.service";

@Component({
  selector: 'parcel-detail',
  templateUrl: './parcel-detail.component.html',
  styleUrls: ['./parcel-detail.component.scss']
})
export class ParcelDetailComponent implements OnInit {

  private static readonly OCCUPATIONS_TAB: TabModel = {
    name: 'Zábory',
    id: 'occupations',
    href: 'symap.project.parcels.detail',
    urlParams: { tab: 'occupations' },
  };

  private static readonly EASEMENTS_TAB: TabModel = {
    name: 'Služebnosti',
    id: 'easements',
    href: 'symap.project.parcels.detail',
    urlParams: { tab: 'easements' },
  };

  private static readonly COSTS_TAB: TabModel = {
    name: 'Náklady na pořízení',
    id: 'costs',
    href: 'symap.project.parcels.detail',
    urlParams: { tab: 'costs' },
  };

  private static readonly OCCUPATION_PERMANENT_COLUMNS: ColumnDefinition[] = [
    { id: 'occupation_concern_object', sortable: false },
    { id: 'occupation_phase', sortable: true },
    { id: 'occupation_occupation', sortable: true },
    { id: 'occupation_cases', sortable: true },
    { id: 'current_parcel', sortable: false },
    { id: 'current_protection', sortable: false },
    { id: 'current_concern_object', sortable: false },
  ];

  private static readonly OCCUPATION_UNDER_YEAR_COLUMNS: ColumnDefinition[] = [
    { id: 'label_gp', sortable: false },
    { id: 'occupation_concern_object', sortable: false },
    { id: 'occupation_phase', sortable: true},
    { id: 'occupation_occupation', sortable: true},
    { id: 'occupation_cases', sortable: true},
    { id: 'current_parcel', sortable: false },
    { id: 'current_protection', sortable: false },
    { id: 'current_concern_object', sortable: false },
  ];

  private static readonly OCCUPATION_OVER_YEAR_COLUMNS: ColumnDefinition[] =
    ParcelDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;

  private static readonly EASEMENT_COLUMNS: ColumnDefinition[] = [
    { id: 'construction_object', sortable: false },
    { id: 'title', sortable: true },
    { id: 'area', sortable: false },
    { id: 'geometric_plan', sortable: false },
    { id: 'length', sortable: false },
    { id: 'case', sortable: true },
    { id: 'vb_tz', sortable: false },
  ];

  private static readonly OCCUPATION_PERMANENT_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'occupation_price_parcel', sortable: false },
    { id: 'occupation_price_vegetation', sortable: false },
    { id: 'occupation_price_construction', sortable: false },
    { id: 'occupation_price_summary', sortable: false },
  ];

  private static readonly OCCUPATION_UNDER_YEAR_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'occupation_assessment_price', sortable: false },
    { id: 'occupation_price_land_price', sortable: false },
    { id: 'occupation_price_rent_price_year', sortable: false},
    { id: 'occupation_price_rent_length', sortable: false },
    { id: 'occupation_price_total_rental_price', sortable: false },
  ];

  private static readonly PERMANENT_WITH_SOLUTION_TYPE_COLUMNS: ColumnDefinition[] =
    [...ParcelDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS, ...ParcelDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS];

  private static readonly OCCUPATION_OVER_YEAR_PRICE_COLUMNS: ColumnDefinition[] =
    ParcelDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;

  private static readonly EASEMENT_PRICE_COLUMNS: ColumnDefinition[] = [
    { id: 'parcel_price', sortable: false },
  ];

  private static readonly TABS_ORDER = ['occupations', 'easements', 'tenant', 'cases', 'buildings', 'lpis', 'notes', 'costs', 'history', 'documents'];

  tabs: TabModel[] = [
    {
      name: 'Nájemce',
      id: 'tenant',
      href: `symap.project.parcels.detail`,
      urlParams: { tab: 'tenant' },
    },
    {
      name: 'Případy',
      id: 'cases',
      href: 'symap.project.parcels.detail',
      urlParams: { tab: 'cases' },
    },
    {
      name: 'Budova',
      id: 'buildings',
      href: 'symap.project.parcels.detail',
      urlParams: { tab: 'buildings' },
    },
    {
      name: 'LPIS',
      id: 'lpis',
      href: 'symap.project.parcels.detail',
      urlParams: { tab: 'lpis' },
    },
    {
      name: 'Poznámky',
      id: 'notes',
      href: 'symap.project.parcels.detail',
      urlParams: { tab: 'notes' },
    },

    {
      name: 'Historie',
      id: 'history',
      href: 'symap.project.parcels.detail',
      urlParams: { tab: 'history' },
    },
    {
      name: 'Dokumenty',
      id: 'documents',
      href: 'symap.project.parcels.detail',
      urlParams: { tab: 'documents' },
    },
  ];

  @Input() parcelId: number;
  tab: string;
  parcel: ParcelModel;
  loading = true;
  caseList: ListModel<CaseModel>;
  noteList: ListModel<NoteModel>;
  historyList: ListModel<NoteModel>;
  occupationList: ListModel<OccupationModel>;
  easementList: ListModel<EasementModel>;
  documentList: ListModel<DocumentModel>;

  occupationGroups: OccupationGroupsType;
  occupationPermanentColumns: ColumnDefinition[];
  occupationUnderOneYearColumns: ColumnDefinition[];
  occupationOverOneYearColumns: ColumnDefinition[];
  permanentWithSolutionTypeColumns: ColumnDefinition[];
  easementColumns: ColumnDefinition[];
  occupationUnderYearTableName: string;
  occupationOverYearTableName: string;
  tenantEdit = false;
  geometricPlanModuleEnabled: boolean;
  cadastreDataDate: string;
  helpIds = HelpService.HELP_IDS;

  constructor(
    private restangular: Restangular,
    private listService: ListService,
    private stateService: StateService,
    private parcelService: ParcelService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private brandService: BrandService,
  ) {
    this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'occupations');
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  async ngOnInit() {
    this.geometricPlanModuleEnabled = this.authService.getActualProject().geometricPlanModuleEnabled;
    await this.loadParcel();
    this.onUpdateLpis();
    await this.loadOccupations();
    await this.loadEasements();
    this.resolveTabs();
    this.prepareParcelColumns();
    this.loadCases();
    this.loadNotes();
    this.loadHistory();
    this.loadDocuments();
    this.loading = false;
  }

  isActiveTab(id: string) {
    return id === this.tab;
  }

  uiOnParamsChanged(changedParams) {
    this.tab = changedParams.tab;
  }

  onUpdateCaseList() {
    const caseTab = this.tabs.find((tab) => tab.id === 'cases');
    caseTab.name = `Případy (${this.caseList.itemCount})`;
  }

  onUpdateLpis() {
    const tab = this.tabs.find((tab) => tab.id === 'lpis');
    if (this.brandService.isVisible('maja') && this.parcel.lpisBloky.length) {
      tab.name = `LPIS (${this.parcel.lpisBloky.length})`;
    } else {
      this.tabs.splice(this.tabs.indexOf(tab), 1);
    }
  }

  onCaseTableSort(event: any) {
    this.listService.sort(this.caseList, event.column, event.ascOrDesc);
  }

  onUpdateNoteList() {
    const noteTab = this.tabs.find((tab) => tab.id === 'notes');
    noteTab.name = `Poznámky (${this.noteList.list.filter(a => !a.cancelled).length})`;
  }

  onUpdateHistoryList() {
    const historyTab = this.tabs.find((tab) => tab.id === 'history');
    historyTab.name = `Historie (${this.historyList.itemCount})`;
  }

  onUpdateOccupationsList() {
    const tab = ParcelDetailComponent.OCCUPATIONS_TAB;
    const count = this.occupationList.itemCount;
    tab.name = `Zábory (${count})`;
    const tabIndex = this.tabs.indexOf(tab);

    if (count === 0 && tabIndex > -1) {
      this.tabs.splice(this.tabs.indexOf(tab), 1);
    } else if (count > 0 && tabIndex === -1) {
      this.tabs.push(tab);
    }
  }

  onUpdateEasementsList() {
    const tab = ParcelDetailComponent.EASEMENTS_TAB;
    const count = this.easementList.itemCount;
    tab.name = `Služebnosti (${count})`;
    const tabIndex = this.tabs.indexOf(tab);

    if (count === 0 && tabIndex > -1) {
      this.tabs.splice(this.tabs.indexOf(tab), 1);
    } else if (count > 0 && tabIndex === -1) {
      this.tabs.push(tab);
    }
  }

  onSubmitCosts() {
    return this.restangular.one(`parcels/${this.parcelId}`).customPUT(this.parcel).toPromise().then(() => {
      this.loadHistory();
    });
  }

  onUpdateDocumentList() {
    const documentTab = this.tabs.find((tab) => tab.id === 'documents');
    documentTab.name = `Dokumenty (${this.documentList.list.filter(a => !a.cancelled).length})`;
  }

  loadHistory() {
    this.historyList = this.listService.createList(
      'notes',
      {
        filters: { idpar: this.parcel.idpar, systemType: true},
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      },
      undefined,
      { subject: { load: true }},
    );
    this.listService.fetchResult(this.historyList).then(() => {
      this.onUpdateHistoryList();
    });
  }

  private loadParcel(): Promise<any> {
    return this.restangular
      .one('parcels', this.parcelId)
      .customPOST({ bpejList: {}, buildings: {}, lpisBloky: {} })
      .toPromise()
      .then(data => {
        this.parcel = data.plain();
        if (!data.endDate && data.platnostK && this.authService.getActualProject().cadastreDataDate != data.platnostK) {
          this.cadastreDataDate = data.platnostK;
        }

        const buildingsTab = this.tabs.find((tab) => tab.id === 'buildings');
        if (!this.parcel.building) {
          this.tabs.splice(this.tabs.indexOf(buildingsTab), 1);
        }
      });
  }

  private loadDocuments() {
    this.documentList = this.listService.createList(
      'attachments',
      {
        filters: {parcelId: this.parcelId, cancelled: false},
        sortOrder: {sortBy: 'timestamp', direction: 'desc'}
      },
      undefined,
      {businessCase: {}}
    );
    this.listService.fetchResult(this.documentList).then(() => {
      this.onUpdateDocumentList();
    });
  }

  private hideColumn(columnID: string): boolean {
    const tableID = 'parcelDetail';
    return this.settingsService.shouldHideColumn(tableID, columnID);
  }

  private prepareParcelColumns() {
    let occupationPermanentColumns = [...ParcelDetailComponent.OCCUPATION_PERMANENT_COLUMNS];
    let occupationUnderOneYearColumns = [...ParcelDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS];
    let occupationOverOneYearColumns = [...ParcelDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS];
    let permanentWithSolutionTypeColumns = [ ...ParcelDetailComponent.PERMANENT_WITH_SOLUTION_TYPE_COLUMNS ];
    let easementColumns = [...ParcelDetailComponent.EASEMENT_COLUMNS];

    if (!this.hideColumn('permanentOccupationPriceColumns')) {
      occupationPermanentColumns = occupationPermanentColumns.concat(ParcelDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
    }
    if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
      occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(ParcelDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
    }
    if (!this.hideColumn('temporaryOverYearPriceColumns')) {
      occupationOverOneYearColumns = occupationOverOneYearColumns.concat(ParcelDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
    }
    if (!this.hideColumn('easementsPriceColumns') && !this.authService.getActualProject().easementPriceByShare) {
      easementColumns = easementColumns.concat(ParcelDetailComponent.EASEMENT_PRICE_COLUMNS);
    }

    if (this.canEdit()) {
      if (!this.hideColumn('temporaryOverYearPriceColumns')) {
        occupationOverOneYearColumns.push({ id: 'update_occupation', sortable: false });
      }
      if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
        occupationUnderOneYearColumns.push({ id: 'update_occupation', sortable: false });
      }
      if (!this.hideColumn('permanentOccupationPriceColumns')) {
        occupationPermanentColumns.push({ id: 'update_occupation', sortable: false });
      }
      permanentWithSolutionTypeColumns.push({ id: 'update_occupation', sortable: false });
    }

    this.occupationPermanentColumns = TableUtils.repairColumnsStructure(occupationPermanentColumns);
    this.permanentWithSolutionTypeColumns = TableUtils.repairColumnsStructure(permanentWithSolutionTypeColumns);
    this.occupationUnderOneYearColumns = TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
    this.occupationOverOneYearColumns = TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
    this.easementColumns = TableUtils.repairColumnsStructure(easementColumns);
  }

  private loadCases() {
    this.caseList = this.listService.createList(
      'cases',
      {
        limit: undefined,
        filters: {
          parcelId: this.parcelId,
          loadCollections: ['titles', 'caseOwnerships', 'constructionObjects']
        }
      }
    );
    this.listService.fetchResult(this.caseList).then(() => {
      this.onUpdateCaseList();
    });
  }

  private loadNotes() {
    this.noteList = this.listService.createList(
      'notes',
      {
        filters: { idpar: this.parcel.idpar, systemType: false, cancelled: false, },
        sortOrder: { sortBy: 'timestamp', direction: 'desc' }
      },
      undefined,
      { title: { load: true },
        businessCase: { load: true },
      }
    );
    this.listService.fetchResult(this.noteList).then(() => {
      this.onUpdateNoteList();
    });
  }

  private loadOccupations(): Promise<any> {
    this.occupationList = this.listService.createList(
      'occupations',
      {
        limit: undefined,
        filters: {
          parcelId: this.parcelId,
          validOrWithCase: true,
          loadCollections: [
            'parcel.knBudouc.currentParcels',
            'parcel.knBudouc.currentParcels.bpejList',
            'constructionObjects',
            'rentalExpert',
          ]},
        sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
      }
    );

    return this.listService.fetchResult(this.occupationList).then((data: ListModel<OccupationModel>) => {
      this.occupationGroups = this.parcelService.groupOccupationsByParcel(data.list, []);
      this.occupationUnderYearTableName = this.parcelService.getTemporaryOccupationName(this.occupationGroups.underOneYear);
      this.occupationOverYearTableName = this.parcelService.getTemporaryOccupationName(this.occupationGroups.overOneYear);
      this.onUpdateOccupationsList();
    });
  }

  private loadEasements(): Promise<any> {
    this.easementList = this.listService.createList(
      'easements',
      {
        limit: undefined,
        filters: {
          parcelId: this.parcelId,
          validOrWithCase: true,
          loadCollections: [],
        },
        sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
      },
      undefined,
      {
        constructionObjects: {
          load: true,
          attributes: {
            administrator: {load: true},
          }
        }
      }
    );

    return this.listService.fetchResult(this.easementList).then(() => {
      this.onUpdateEasementsList();
    });
  }

  private resolveTabs() {
    // if no occupations go to easements
    const easementsCount = this.easementList.itemCount;
    const occupationsCount = this.occupationList.itemCount;

    if (occupationsCount === 0 && this.tab === 'occupations') {
      if (easementsCount === 0) {
        // redirect if path is active
        this.stateService.go('.', { tab: 'tenant' }, { location: 'replace' });
      } else {
        this.stateService.go('.', { tab: 'easements' }, { location: 'replace' });
      }
    }

    if (easementsCount === 0 && this.tab === 'easements') {
      // redirect if path is active
      this.stateService.go('.', { tab: 'tenant' }, { location: 'replace' });
    }

    // check if show parcels costs tab
    if (this.authService.hasPermission('assignable') && this.occupationGroups.permanent) {
      this.tabs.push(ParcelDetailComponent.COSTS_TAB);
    } else if (this.tab === 'costs') {
      this.stateService.go('.', { tab: 'easements' }, { location: 'replace' });
    }

    this.tabs.sort((a, b) => {
      return ParcelDetailComponent.TABS_ORDER.indexOf(a.id) - ParcelDetailComponent.TABS_ORDER.indexOf(b.id);
    });
  }

  public canEdit(): boolean {
    return this.authService.isAuthorized() || this.authService.hasPermission('assignable');
  }
}
