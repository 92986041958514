export const variables = {
  GOOGLEANALYTICSKEYS: {
      production: "G-V47794HMQF",
      development: "G-SWN1QR13GK",
  },
  MULTI_SCENE_CLOUD: {
  },
  LOCALSTORAGEVERSION: 18,
  SERVICEDESK: {
      BASEURL: "https://gmtech-cz.atlassian.net/servicedesk/customer/portal/61",
      PORTALSY: 7,
      PORTALDI: 55,
      PORTALPRO: 56,
      PORTALSETTINGS: 57,
      CREATELOGINERROR: 17
  }
, VERSION: '24-07' };