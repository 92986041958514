﻿import { ActingPersonModel } from '@app/ps/models/acting-person.model';
import { CaseModel } from '@app/ps/models/case.model';
import { SettlementProgress } from '@app/ps/models/settlement-progress';
import { SubjectDetailModel } from '@app/ps/models/subject-detail.model';

export class SubjectModel {
  id: number;
  opsubId: number;
  nazev: string;
  prijmeni: string;
  jmeno: string;
  titulPredJmenem: string;
  titulZaJmenem: string;
  ulice?: string;
  cisloDomovni?: number;
  cisloOrientacni?: string;
  castObce?: string;
  mestskaCast?: string;
  mestskyObvod?: string;
  obec?: string;
  okres?: string;
  psc?: string;
  ico?: number;
  stat: string;
  opsubType: 'OFO' | 'OPO' | 'BSM';
  paymentType: string;
  actingPersons?: ActingPersonModel[];
  subjectDetail: SubjectDetailModel;
  businessCase?: CaseModel;
  occupationResolution?: SettlementProgress[];
  email?: string;
  databoxId?: string;
  phoneNumber?: string;
  cpCe?: number;
  kodStatu?: string;
  kodStatuAl2?: string;
}
