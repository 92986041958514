﻿import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { ListService } from '@app/common/services/list.service';
import { AuthService } from '@app/common/services/auth.service';
import { ParcelNumberPipe } from '@app/common/pipes/parcel-number.pipe';
import { PageableListService } from '@app/common/services/pageable.list.service';
import { PageableList } from '@app/models/pageable.list.ts';
import { WordTranslatePipe } from '@app/common/pipes/word-translate.pipe';
import * as _ from 'lodash';
import { DialogService } from '@app/common/services/dialog.service';
import { ChecklistModel } from '@app/common/models/checklist.model';
import { ParcelModel } from '@app/common/models/parcel.model';
import { OccupationModel } from '@app/ps/models/occupation.model';

@Component({
  selector: 'construction-object-detail-create-case-select-easement',
  templateUrl: './create-case-select-easement.component.html',
})
export class CreateCaseSelectEasementComponent implements OnInit {
  @Input()
  public data: any;
  @Output()
  private submitCallback = new EventEmitter();
  @Output()
  private backCallback = new EventEmitter();
  public loading = false;
  public easementPriceByShare: boolean;
  public geometricPlanIndex = 0;
  public totalSelected = 0;
  public easementPageableList: PageableList;
  public allEnabledEasements: any[] = [];

  constructor(
    private authService: AuthService,
    private parcelNumberPipe: ParcelNumberPipe,
    private listService: ListService,
    private pageableListService: PageableListService,
    private wordTranslatePipe: WordTranslatePipe,
    private dialogService: DialogService,
  ) {
    this.submit = this.submit.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  ngOnInit() {
    delete this.data.ownershipsWithParent;
    this.data.easementsSelected = this.data.easementsSelected || [];
    const project = this.authService.getActualProject();
    this.easementPriceByShare = project.easementPriceByShare;
    this.data.geometricPlansSelected.forEach((geometricPlan, index) => {
      geometricPlan.checkedAll = geometricPlan.easementChecklist && geometricPlan.checkedAll === true;
      this.totalSelected += this.getSelectedCount(index);
      if (!geometricPlan.easementChecklist) {
        geometricPlan.easementChecklist = new ChecklistModel();
      }
    });
    this.easementPageableList = this.pageableListService.get(
      'easements',
      {
        sortOrder: [{sortBy: 'parcel.lv'}],
        filters: {
          loadCollections: ['parcel.title.ownerships', 'parcel.occupations'],
          validity: ['valid'],
          gpId: [],
          usersEasements: [],
        },
      },
      undefined, undefined,
      {constructionObjects: {}},
    );
    this.load(0);
    this.loadAllEnabledEasements();
  }

  loadAllEnabledEasements() {
    this.loading = true;
    const list = this.listService.createList('easements', {
      limit: null,
      sortOrder: [{sortBy: 'parcel.lv'}],
      filters: {
        validity: ['valid'],
        gpId: this.data.geometricPlansSelected.map(gp => gp.id),
        usersEasements: [],
      },
    });
    this.listService.fetchResult(list).then(data => {
      this.allEnabledEasements = data.list.filter(easement => !this.isDisabled(easement));
      this.data.geometricPlansSelected.forEach(gp => {
        gp.enabledEasementsCount = this.allEnabledEasements.filter(easement => easement.geometricPlan.id === gp.id).length;
      });
      this.loading = false;
    });

  }

  getSelectedCount(geometricPlanIndex: number = this.geometricPlanIndex): number {
    if (!this.data.geometricPlansSelected[geometricPlanIndex].easementChecklist) {
      return 0;
    }
    return this.data.geometricPlansSelected[geometricPlanIndex].easementChecklist.checkedItems.length;
  }

  pageChanged(pageNumber: number) {
    this.easementPageableList.fetchResults(pageNumber);
    this.easementPageableList.onListChanged();
  }

  load(geometricPlanIndex: number) {
    const geometricPlan = this.data.geometricPlansSelected[geometricPlanIndex];
    this.easementPageableList.list.list = null;
    this.easementPageableList.list.filter.filters.gpId = [geometricPlan.id];
    return this.easementPageableList.fetchResults(1).then(() => {
      this.easementPageableList.onListChanged();
    });
  }

  isChecked(easementCheck) {
    return !this.isDisabled(easementCheck) && this.data.geometricPlansSelected[this.geometricPlanIndex].easementChecklist.isChecked(easementCheck);
  }

  toggleSelectionEasement(easementCheck) {
    let check = this.data.geometricPlansSelected[this.geometricPlanIndex].easementChecklist.toggleSelection(easementCheck);
    let globalPromise = Promise.resolve(true);
    this.allEnabledEasements.forEach((easement) => {
      if (!this.isDisabled(easement) && easementCheck.parcel.id === easement.parcel.id) {
        const geometricPlan = this.data.geometricPlansSelected.find((gp) => gp.id == easement.geometricPlan.id);
        let promise;
        if (easement.id === easementCheck.id) {
          promise = Promise.resolve(true);
        } else if (check === this.isChecked(easement)) {
          promise = Promise.resolve(false);
        } else {
          promise = globalPromise = globalPromise.then(() => this.dialogService.confirmDialogPromise('Na parcele ' + this.parcelNumberPipe.transform(easementCheck.parcel) + ' se nachází další věcné břemeno geometrického plánu ' + geometricPlan.cis + '. Přejete si jej také ' + (check ? 'zvolit' : 'odvybrat') + '?'));
          promise.then((result) => {
            if (result) {
              geometricPlan.easementChecklist.toggleSelection(easement);
            }
          });
        }
        promise.then((result) => {
          if (result) {
            this.totalSelected += check ? 1 : -1;
            if (check) {
              this.data.easementsSelected.push(easement);
            } else {
              geometricPlan.checkedAll = false;
              _.remove(this.data.easementsSelected, (easementSelected: any) => easement.id === easementSelected.id);
            }
          }
        });
      }
    });

    return globalPromise;
  }

  checkAll() {
    const geometricPlan = this.data.geometricPlansSelected[this.geometricPlanIndex];
    this.totalSelected -= this.getSelectedCount();
    geometricPlan.checkedAll = !geometricPlan.checkedAll;
    if (geometricPlan.checkedAll) {
      this.allEnabledEasements.filter(easement => easement.geometricPlan.id === geometricPlan.id).forEach(easement => {
        if (!geometricPlan.easementChecklist.isChecked(easement)) {
          this.data.easementsSelected.push(easement);
          geometricPlan.easementChecklist.checkedItems.push(easement);
        }
      });
    } else {
      geometricPlan.easementChecklist.checkedItems.forEach(checkedEasement => {
        _.remove(this.data.easementsSelected, (easement: any) => easement.id === checkedEasement.id);
      });
      geometricPlan.easementChecklist.empty();
    }
    this.totalSelected += this.getSelectedCount();
  }

  getTotalSelected(): number {
    return this.totalSelected;
  }

  isDisabled(easement) {
    return this.getDisabledReason(easement).length > 0;
  }

  getDisabledReason(easement) {
    let reason = [];
    if ((this.data.obligation.type === 'RealBurdenLiability' && easement.existsCaseBlockingPrice) || (this.data.obligation.type === 'RealBurdenLiabilityAccelerated' && easement.acceleratedCaseStatus)) {
      reason.push('Případ již existuje');
    }
    if (easement.easementPrice === undefined && !this.easementPriceByShare && this.data.obligation.computePrice) {
      if (new Set(easement.constructionObjects.map(co => co.easementGlobalPriceType)).size > 1 ||
        new Set(easement.constructionObjects.map(co => co.easementGlobalOtherUnitType)).size > 1 ||
        new Set(easement.constructionObjects.map(co => co.easementGlobalOtherLengthPrice)).size > 1 ||
        new Set(easement.constructionObjects.map(co => co.easementGlobalOtherAreaPrice)).size > 1 ||
        new Set(easement.constructionObjects.map(co => co.easementGlobalOtherSinglePrice)).size > 1) {
        reason.push('Zadejte shodnou cenu VB všech souvisejích ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV'));
      } else {
        reason.push('Zadejte cenu VB');
      }
    }
    if (easement.parcel.endDate) {
      reason.push('Neplatná parcela');
    }
    if (easement.parcel.title && easement.parcel.title.blockedByUser) {
      reason.push('Odblokujte LV');
    }
    return reason;
  }

  previous() {
    this.geometricPlanIndex--;
    this.load(this.geometricPlanIndex);
  }

  next() {
    this.geometricPlanIndex++;
    this.load(this.geometricPlanIndex);
  }

  submit() {
    return this.submitCallback.emit();
  }

  isPermanentOccupationOnParcel(parcel: ParcelModel): boolean {
    if (!parcel) {
      return false;
    }

    return !!parcel.occupations.find(o => o.occupationType.category === 'permanent' && !o.endDate);
  }
}
