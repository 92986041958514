import {BuildingModel} from '@app/common/models/building.model';
import { ParcelPriceCoefficient, ParcelPriceModel } from '@app/ps/models/parcel-price.model';
import {ParcelModel} from '@app/common/models/parcel.model';

export class ParcelPriceUtils {

  public static multipleLandCoefficient(parcelPrice: ParcelPriceModel): boolean {
    return [
      parcelPrice.expertOpinionCoefficientsFlatten.land1Multiply,
      parcelPrice.expertOpinionCoefficientsFlatten.land15Multiply,
      parcelPrice.expertOpinionCoefficientsFlatten.land8Multiply,
    ].filter(i => i).length > 1;
  }

  public static computeLandPriceCoefficient(parcelPrice: ParcelPriceModel): number {
    if (!parcelPrice.parcel) {
      return 0;
    }

    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    if (ParcelPriceUtils.multipleLandCoefficient(parcelPrice)) {
      let landPriceCoefficient = 0;
      if (coefficients.land8Multiply) {
        landPriceCoefficient += coefficients.land8SquarePrice * coefficients.land8Area * ParcelPriceCoefficient.COEFFICIENT_8;
      }
      if (coefficients.land15Multiply) {
        landPriceCoefficient += coefficients.land15SquarePrice * coefficients.land15Area * ParcelPriceCoefficient.COEFFICIENT_1_5;
      }
      if (coefficients.land1Multiply) {
        landPriceCoefficient += coefficients.land1SquarePrice * coefficients.land1Area * ParcelPriceCoefficient.COEFFICIENT_1;
      }

      return Math.round(landPriceCoefficient);
    } else {
      let coefficient = 1;

      if (coefficients.land8Multiply) {
        coefficient = ParcelPriceCoefficient.COEFFICIENT_8;
      } else if (coefficients.land15Multiply) {
        coefficient = ParcelPriceCoefficient.COEFFICIENT_1_5;
      } else if (coefficients.land1Multiply) {
        coefficient = ParcelPriceCoefficient.COEFFICIENT_1;
      }

      return Math.round(parcelPrice.expertOpinionLandSquarePrice * parcelPrice.parcel.vymera * coefficient) || 0;
    }
  }

  public static computeLandPriceCoefficientFromDefault(parcelPrice: ParcelPriceModel): number {
    if (!parcelPrice.parcel) {
      return 0;
    }

    const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;

    if (ParcelPriceUtils.multipleLandCoefficient(parcelPrice)) {
      let landPrice = 0;
      if (coefficients.land8Multiply) {
        landPrice += coefficients.land8SquarePrice * coefficients.land8Area;
      }
      if (coefficients.land15Multiply) {
        landPrice += coefficients.land15SquarePrice * coefficients.land15Area;
      }
      if (coefficients.land1Multiply) {
        landPrice += coefficients.land1SquarePrice * coefficients.land1Area;
      }

      landPrice = Math.round(landPrice);

      let landPriceCoefficientByDefault = 0;
      if (coefficients.land8Multiply) {
        landPriceCoefficientByDefault += parcelPrice.expertOpinionLandPriceDefault * (coefficients.land8SquarePrice * coefficients.land8Area / landPrice) * ParcelPriceCoefficient.COEFFICIENT_8;
      }
      if (coefficients.land15Multiply) {
        landPriceCoefficientByDefault += parcelPrice.expertOpinionLandPriceDefault * (coefficients.land15SquarePrice * coefficients.land15Area / landPrice) * ParcelPriceCoefficient.COEFFICIENT_1_5;
      }
      if (coefficients.land1Multiply) {
        landPriceCoefficientByDefault += parcelPrice.expertOpinionLandPriceDefault * (coefficients.land1SquarePrice * coefficients.land1Area / landPrice) * ParcelPriceCoefficient.COEFFICIENT_1;
      }

      return Math.round(landPriceCoefficientByDefault);
    } else {
      let coefficient = 1;

      if (coefficients.land8Multiply) {
        coefficient = ParcelPriceCoefficient.COEFFICIENT_8;
      } else if (coefficients.land15Multiply) {
        coefficient = ParcelPriceCoefficient.COEFFICIENT_1_5;
      } else if (coefficients.land1Multiply) {
        coefficient = ParcelPriceCoefficient.COEFFICIENT_1;
      }

      return Math.round(parcelPrice.expertOpinionLandPriceDefault / parcelPrice.parcel.vymera * parcelPrice.parcel.vymera * coefficient) || 0;
    }
  }

  public static computeLandPriceFromMultiple(parcelPrice: ParcelPriceModel) {
      const coefficients = parcelPrice.expertOpinionCoefficientsFlatten;
      let landPrice = 0;
      if (coefficients.land8Multiply) {
        landPrice += coefficients.land8SquarePrice * coefficients.land8Area;
      }
      if (coefficients.land15Multiply) {
        landPrice += coefficients.land15SquarePrice * coefficients.land15Area;
      }
      if (coefficients.land1Multiply) {
        landPrice += coefficients.land1SquarePrice * coefficients.land1Area;
      }

      return Math.round(landPrice);
  }

  public static buildingsMatch(building: BuildingModel, parcelPrice: ParcelPriceModel): boolean {
    return parcelPrice.building && parcelPrice.building.budId === building.budId;
  }

  public static parcelsMatch(parcel: ParcelModel, parcelPrice: ParcelPriceModel): boolean {
    return parcelPrice.parcel && parcelPrice.parcel.idpar === parcel.idpar;
  }
}
